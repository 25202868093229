<template>
  <div class="app-container h">
    <div class="v no-flex" style="width: 320px; margin-right: 20px;">
      <div class="bold" style="line-height: 2;">自定义区域</div>
      <div class="scroll-able-xy flex" style="border: 1px solid #DCDFE6; border-radius: 4px;">
        <dept-select v-model="query.treeNodeId" :type.sync="query.treeNodeType" @change="doQuery" />
      </div>
    </div>
    <div class="flex v">
      <div class="h c" style="margin-bottom: 10px;">
        <quick-select v-model="query.distributorId" url="api/distributor" placeholder="经销商" clearable filterable @change="toQuery" value-field="enterpriseId" style="width: 180px; margin-right: 10px;" />
        <el-button type="success" :loading="downloadLoading" @click="toDownload">导出</el-button>
        <div class="flex"></div>
        <el-alert type="info" show-icon :closable="false" :title="formula" style="width: auto;"></el-alert>
      </div>

      <el-card class="no-flex" body-style="padding: 0;" style="margin-bottom: 10px;" shadow="never" v-if="summary">
        <div class="h c">
          <div class="padding-10" style="width: 18%;">
            <div class="ta-c bold">期初</div>
            <div class="h ta-c gap-1x">
              <div style="width: 35%">
                <div class="fs-mini fc-g">数量</div>
                <div class="bold">{{summary.bopQuan.toFixed(2)}}</div>
              </div>
              <div style="width: 65%">
                <div class="fs-mini fc-g">期初库存成本</div>
                <div class="bold">{{$price(summary.bopAmount)}}</div>
              </div>
            </div>
          </div>
          <el-divider direction="vertical" />
          <div class="padding-10" style="width: 18%;">
            <div class="ta-c bold">工厂发货</div>
            <div class="h ta-c gap-1x">
              <div style="width: 35%">
                <div class="fs-mini fc-g">数量</div>
                <div class="bold">{{summary.deliveryQuan.toFixed(2)}}</div>
              </div>
              <div style="width: 65%">
                <div class="fs-mini fc-g">采购成本</div>
                <div class="bold">{{$price(summary.deliveryAmount)}}</div>
              </div>
            </div>
          </div>
          <el-divider direction="vertical" />
          <div class="padding-10" style="width: 27%;">
            <div class="ta-c bold">终端销售</div>
            <div class="h ta-c gap-1x">
              <div style="width: 25%">
                <div class="fs-mini fc-g">数量</div>
                <div class="bold">{{summary.retailQuan.toFixed(2)}}</div>
              </div>
              <div style="width: 37%">
                <div class="fs-mini fc-g">销售额</div>
                <div class="bold">{{$price(summary.retailSaleAmount)}}</div>
              </div>
              <div style="width: 37%">
                <div class="fs-mini fc-g">产品成本</div>
                <div class="bold">{{$price(summary.retailAmount)}}</div>
              </div>
            </div>
          </div>
          <el-divider direction="vertical" />
          <div class="padding-10" style="width: 18%;">
            <div class="ta-c bold">调整</div>
            <div class="h ta-c gap-1x">
              <div style="width: 35%">
                <div class="fs-mini fc-g">数量</div>
                <div class="bold">{{summary.changeQuan.toFixed(2)}}</div>
              </div>
              <div style="width: 65%">
                <div class="fs-mini fc-g">产品成本</div>
                <div class="bold">{{$price(summary.changeAmount)}}</div>
              </div>
            </div>
          </div>
          <el-divider direction="vertical" />
          <div class="padding-10" style="width: 18%;">
            <div class="ta-c bold">净库存</div>
            <div class="h ta-c gap-1x">
              <div style="width: 35%">
                <div class="fs-mini fc-g">数量</div>
                <div class="bold">{{summary.balanceQuan.toFixed(2)}}</div>
              </div>
              <div style="width: 65%">
                <div class="fs-mini fc-g">产品成本</div>
                <div class="bold">{{$price(summary.balanceAmount)}}</div>
              </div>
            </div>
          </div>
        </div>
      </el-card>

      <el-table v-loading="loading" row-key="id" :data="data" size="small" border height="530">
        <!-- <el-table-column type="index" width="50" /> -->
        <el-table-column prop="erpId" label="客户编码" min-width="110" />
        <el-table-column prop="name" label="客户名称" min-width="200" show-overflow-tooltip />
        <el-table-column label="期初" align="center">
          <el-table-column prop="bopQuan" label="数量" min-width="70" align="center" />
          <el-table-column prop="bopAmount" label="期初库存成本" min-width="110" :formatter="$price" align="right" />
        </el-table-column>
        <el-table-column label="工厂发货" align="center">
          <el-table-column prop="deliveryQuan" label="数量" min-width="70" align="center" />
          <el-table-column prop="deliveryAmount" label="采购成本" min-width="110" :formatter="$price" align="right" />
        </el-table-column>
        <el-table-column label="终端销售" align="center">
          <el-table-column prop="retailQuan" label="数量" min-width="70" align="center" />
          <el-table-column prop="retailSaleAmount" label="销售额" min-width="110" :formatter="$price" align="right" />
          <el-table-column prop="retailAmount" label="产品成本" min-width="110" :formatter="$price" align="right" />
        </el-table-column>
        <el-table-column label="调整" align="center">
          <el-table-column prop="changeQuan" label="数量" min-width="70" align="center" />
          <el-table-column prop="changeAmount" label="产品成本" min-width="110" :formatter="$price" align="right" />
        </el-table-column>
        <el-table-column label="净库存" align="center">
          <el-table-column prop="balanceQuan" label="数量" min-width="70" align="center" />
          <el-table-column prop="balanceAmount" label="产品成本" min-width="110" :formatter="$price" align="right" />
        </el-table-column>
      </el-table>
      <el-pagination align="center" :current-page="page + 1" :page-size.sync="size" layout="total, sizes, prev, pager, next, jumper" @current-change="pageChange" @size-change="toQuery" :total="total" style="margin-top: 10px;" />
    </div>
  </div>
</template>

<script>
import deptSelect from "../distributors/deptSelect";
import initData from "@/mixins/initData";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import request from "@/utils/request";
import { getStatementType } from "@/api/capitalPool";

export default {
  mixins: [initData],
  components: {
    deptSelect,
  },
  data() {
    return {
      query: {
        treeNodeId: null,
        treeNodeType: null,
      },
      statementType: null,
      downloadLoading: false,
      size: 20,
      summary: null,
    };
  },
  computed: {
    formula() {
      let s = "计算公式：期初+工厂发货-终端销售±调整=净库存";
      // if (this.statementType === "kingkoil") {
      //   s += "（从2023年7月1日开始统计）";
      // } else if (this.statementType === "serta") {
      //   s += "（从2023年5月1日开始统计）";
      // } else {
      //   s += "（金可儿从2023年7月1日开始统计，舒达从2023年5月1日开始统计）";
      // }
      s += "（从2024年1月1日开始统计）";
      return s;
    },
  },
  methods: {
    beforeInit() {
      this.url = "@host:analysis;api/analy/stockReport/distributor";
      this.params = Object.assign(
        {
          page: this.page,
          size: this.size,
        },
        this.query
      );
      if (this.page === 0) this.loadSummary();
      return true;
    },
    loadSummary() {
      request({
        url: "@host:analysis;api/analy/stockReport/total",
        method: "get",
        params: this.params,
      })
        .then((res) => {
          this.summary = res;
        })
        .catch((err) => {
          this.summary = null;
        });
    },
    doQuery() {
      this.page = 0;
      this.init();
    },
    toDownload() {
      this.downloadLoading = true;
      download(
        "@host:analysis;api/analy/stockReport/distributor/download",
        this.query
      )
        .then((result) => {
          downloadFile(result, "客户净库存报表", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
  mounted() {
    getStatementType().then((res) => {
      this.statementType = res;
    });
  },
};
</script>